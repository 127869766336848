const MetaData = (path) => {
  const data = {
    '/': {
      title: 'Danny Broadbent | Software Engineer (React, Angular, Symfony, Laravel)',
      description: `I have worked with many clients building various websites and projects, including designing / developing projects, securing websites and maintaining projects over the past 18 years (11 years commercially).`,
    },

    '/skills': {
      title: 'My Skills / Experience',
      description: `I started my development career when I was young, I now have 18 years of knowledge and experience (11 years commercially).`,
    },

    '/work': {
      title: 'My Portfolio | Websites | Apps',
      description: `Take a look at my extensive portfolio of websites and applications I've built.`,
    },

    '/contact': {
      title: 'Contact Me',
      description: `Want to reach out? I'd love to hear from you!`,
    }
  };

  return data[path];
};

export default MetaData;
