import { Link } from 'react-router-dom';
import { useState } from "react";
import MobileMenu from "../MobileMenu";
import Nav from "../Nav";

import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './style.module.scss';
import Logo from "../../components/Logo";

const Header = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  const openMobileNav = () => {
    setShowMobileNav(true);
  };
  const closeMobileNav = () => {
    setShowMobileNav(false);
  };

  return <>
    {showMobileNav ? <MobileMenu closeMobileNav={closeMobileNav} /> : '' }
    <header className={styles.header}>
      <nav className={styles.nav}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.col + ' ' + styles.col_logo}>
              <Logo />
            </div>
            <div className={styles.col + ' ' + styles.col_title}>
              <Link to="/">
                <div>Danny&nbsp;Broadbent</div>
                <div>Full&nbsp;Stack&nbsp;Engineer</div>
              </Link>
            </div>
            <div className={styles.col + ' ' + styles.col_links}>
              <button onClick={openMobileNav}>
                <FontAwesomeIcon icon={faBars} size="2x" />
              </button>
              <Nav type="header" styles={styles} />
            </div>
          </div>
        </div>
      </nav>
    </header>
  </>;
};

export default Header;
