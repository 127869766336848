import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Header from "../layout/Header/index";
import Footer from "../layout/Footer/index";

import Meta from "../components/Meta";
import MetaData from "../data/MetaData";

const Layout = () => {
  const location = useLocation();
  const metaData = MetaData(location.pathname);
  const [
    metaTitle,
    setMetaTitle,
    metaDescription,
    setMetaDescription
  ] = Meta();

  useEffect(() => {
    // Update Meta Title
    if (undefined === metaTitle ||
      0 === metaTitle.length || (
      0 !== metaData?.title.length &&
      metaTitle !== metaData?.title
    )) {
      setMetaTitle(metaData?.title);
    }

    // Update Meta Description
    if (undefined === metaDescription ||
      0 === metaDescription.length || (
      0 !== metaData?.description.length &&
      metaDescription !== metaData?.description
    )) {
      setMetaDescription(metaData?.description);
    }
  });

  return <>
    <Header />

    <main>
      <Outlet />
    </main>

    <Footer />
  </>
};

export default Layout;
