import { Link } from 'react-router-dom';
import Nav from "../Nav";

import { faTimes} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './style.module.scss';
import Logo from "../../components/Logo";

const MobileMenu = ({ closeMobileNav }) => {
  const close = () => {
    closeMobileNav();
  };

  return <div className={styles.mobile_menu}>
    <nav className={styles.nav}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.col + ' ' + styles.col_logo}>
            <Logo closeMobileNav={close} />
          </div>
          <div className={styles.col + ' ' + styles.col_title}>
            <Link to="/" onClick={close}>
              <div>Danny&nbsp;Broadbent</div>
              <div>Full&nbsp;Stack&nbsp;Engineer</div>
            </Link>
          </div>
          <div className={styles.col + ' ' + styles.col_close}>
            <button onClick={close}>
              <FontAwesomeIcon icon={faTimes} size="2x" />
            </button>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col + ' ' + styles.col_links}>
            <Nav type="mobile" styles={styles} closeMobileNav={close} />
          </div>
        </div>
      </div>
    </nav>
  </div>;
};

export default MobileMenu;
