import { Link } from "react-router-dom";
import Image from "../Image";

import styles from './style.module.scss';

const Logo = ({ closeMobileNav }) => {
  const close = () => {
    closeMobileNav();
  };

  return <Link to="/" className={styles.logo} onClick={close}>
    <Image src="images/me90" width={86} height={86} />
  </Link>;
};

export default Logo;
