import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import Header from "../../layout/Header";
import Footer from "../../layout/Footer";

import styles from './style.module.scss';

const Loading = () => {
  return <>
    <Header />
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <FontAwesomeIcon icon={faSpinner} className="fa-spin" />&nbsp;
          Loading...
        </div>
      </div>
    </div>
    <Footer />
  </>;
};

export default Loading;
