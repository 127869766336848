import { Link } from "react-router-dom";
import { faEnvelope} from "@fortawesome/free-regular-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Nav = ({ type, styles, closeMobileNav }) => {
  const onClick = () => {
    closeMobileNav();
  };

  return <ul>
    <li>
      <Link to="/work" onClick={'mobile'===type? onClick : ''}>
        <button className={styles.button}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          My Work
        </button>
      </Link>
    </li>
    {/*<li>*/}
    {/*  <Link to="/skills" onClick={'mobile'===type? onClick : ''}>*/}
    {/*    <button className={styles.button}>*/}
    {/*      <FontAwesomeIcon icon={faTools} />*/}
    {/*      My Skills*/}
    {/*    </button>*/}
    {/*  </Link>*/}
    {/*</li>*/}
    <li>
      <Link to="/contact" onClick={'mobile'===type? onClick : ''}>
        <button className={styles.button}>
          <FontAwesomeIcon icon={faEnvelope} />
          Contact
        </button>
      </Link>
    </li>
  </ul>;
}

export default Nav;
