import { Link } from "react-router-dom";

import {
  faAngellist,
  faFacebook,
  faGithub,
  faLinkedin,
  faStackOverflow
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from './style.module.scss';

const Footer = () => {
  const year = new Date().getFullYear();

  return <footer className={styles.footer}>
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col + ' ' + styles.col_text}>
          <div>
            {year} &copy; Danny Broadbent. All rights reserved.
          </div>
        </div>
        <div className={styles.col + ' ' + styles.col_social}>
          <ul>
            <li>
              <a href="https://www.facebook.com/danielpbroadbent" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/dannybroadbent" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </li>
            <li>
              <a href="https://angel.co/u/danny-phillip-broadbent" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faAngellist} />
              </a>
            </li>
            <li>
              <a href="https://github.com/dannypbroadbent" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faGithub} />
              </a>
            </li>
            <li>
              <a href="https://stackoverflow.com/users/4458089/danny-broadbent" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faStackOverflow} />
              </a>
            </li>
            <li>
              <Link to="/contact">
                <FontAwesomeIcon icon={faEnvelope} />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>;
};

export default Footer;
