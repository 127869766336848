import React, { lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.scss';

import Layout from './pages/Layout';
import Loading from './components/Loading/index';

const Home = lazy(() => import('./pages/Home/index'));
const Work = lazy(() => import('./pages/Work'));
const Skills = lazy(() => import('./pages/Skills'));
const Contact = lazy(() => import('./pages/Contact'));

const NoPage = lazy(() => import('./pages/NoPage/index'));

function App() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />

            <Route path="/work" element={<Work />} />
            <Route path="/skills" element={<Skills />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
